import React__default, { useState } from 'react';
import { exists } from '../../../typedash/dist/index.js';
import MaterialTooltip from '../../../../node_modules/@mui/material/Tooltip/Tooltip.js';

const Tooltip = ({ placement, title, open, children }) => {
    const [tooltipState, setTooltipState] = useState(false);
    return (React__default.createElement(MaterialTooltip, { open: exists(open) ? open : tooltipState, title: title, placement: placement, onMouseEnter: () => {
            setTooltipState(true);
        }, onMouseLeave: () => {
            setTooltipState(false);
        } }, children));
};

export { Tooltip };
